import React from "react";
// import { Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";
import Layout from "../components/layout";
import MainArticle from "../components/mainarticle";
import RelateCard from "../components/relatecard";
import {graphql} from "gatsby";

const CardContainer = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  -ms-grid-columns: 50% 50%;
  row-gap: 12px;
  justify-items: center;
  margin: 16px auto;
  max-width: 790px;
  > div:nth-child(1) {
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }
  > div:nth-child(2) {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
  }
  > div:nth-child(3) {
    -ms-grid-column: 1;
    -ms-grid-row: 2;
  }
  > div:nth-child(4) {
    -ms-grid-column: 2;
    -ms-grid-row: 2;
  }
  > div:nth-child(n+5) {
    display: none;
  }
`;

const StyledTitle = styled.div`
  max-width: 760px;
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 3px solid rgba(0, 0, 0, 0.8);
  align-items: center;
  justify-items: start;
  margin: 0 auto 20px;

  h2 {
    text-align: left;
    font-size: 18px;
    margin: 8px;
    padding-left: 8px;
  }
`;

const ImageZoomContainer = styled.div`
  .fade-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0);
    opacity: 0;
    visibility: hidden;
    z-index: 10000;
    transition: all 300ms ease 0s;
    transform: translate3d(0,0,0);
  }
  
  .fade-layer-open {
    visibility: visible;
    background: rgba(0,0,0,0.75);
    cursor: pointer;
    opacity: 1;
  }
  
  .article-image-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    padding: 20px;
    visibility: hidden;
    transform: translate3d(-50%, -50%, 0);
  }
  
  .article-image-modal-open {
    visibility: visible;
  }
`

const RelatedContainer = styled.div`
  margin-top: 40px;
`

class ImageZoom extends React.Component {
  constructor (props) {
    super(props)
    this.clickHandler = this.onClick.bind(this)
    this.screenClickHandler = this.onScreenClick.bind(this)
    this.fadeClosedHandler = this.onFadeClosed.bind(this)
    this.fadeOpenedHandler = this.onFadeOpened.bind(this)
    this.state = {
      fadeOpenTarget: null,
      isFadeOpened: false,
      elements: []
    }
  }

  getImageLinks() {
    const imageLinks = Array.prototype.slice.call(document.querySelectorAll('.gatsby-resp-image-wrapper'), 0)
//    console.log(imageLinks)
    return imageLinks
  }

  componentDidMount() {
    this.addClickEvent()
    const elements = []
    this.getImageLinks().forEach( (node, i) => {
      node.setAttribute('data-node-id', 'node-'+i)
      elements.push(node)
    })
    this.setState({elements: elements})
  }

  componentWillUnmount() {
    this.setState({elements: []})
    this.removeClickEvent()
    this.removeFadeClosedEvent()
    this.removeFadeOpenedEvent()
  }

  addClickEvent() {
    this.getImageLinks().forEach( (node, i) => {
//      node.addEventListener('touchstart', this.clickHandler, {passive: true})
      node.addEventListener('click', this.clickHandler, false)
    })
  }

  removeClickEvent() {
    this.getImageLinks().forEach( (node, i) => {
//      node.removeEventListener('touchstart', this.clickHandler, {passive: true})
      node.removeEventListener('click', this.clickHandler, false)
    })
  }

  addFadeOpenedEvent() {
    const fade = document.querySelector('.fade-layer')
    fade.addEventListener('transitionend', this.fadeOpenedHandler, false)
  }

  removeFadeOpenedEvent() {
    const fade = document.querySelector('.fade-layer')
//    console.log(fade)
    fade.removeEventListener('transitionend', this.fadeOpenedHandler, false)
  }

  addFadeClosedEvent() {
    const fade = document.querySelector('.fade-layer')
    fade.addEventListener('transitionend', this.fadeClosedHandler, false)
  }

  removeFadeClosedEvent() {
    const fade = document.querySelector('.fade-layer')
//    console.log(fade)
    fade.removeEventListener('transitionend', this.fadeClosedHandler, false)
  }

  onFadeClosed(e) {
//    console.log('fade closed')
    this.addClickEvent()
//    console.log('remove fade closed')
    this.removeFadeClosedEvent()
  }

  onFadeOpened(e) {
    this.setState({isFadeOpened: true})
    this.removeFadeOpenedEvent()
  }

  onClick (e) {
//    console.log('on trigger', e.type)
//     e.preventDefault()
//     e.stopPropagation()
    this.removeClickEvent()
    const currentTarget = document.getElementById(e.currentTarget.getAttribute('data-node-id'))
    this.setState({fadeOpenTarget: currentTarget})
    this.addFadeOpenedEvent()
  }

  onScreenClick (e) {
//    console.log('close fade', e.type)
//     e.preventDefault()
//     e.stopPropagation()
    this.setState({
      isFadeOpened: false,
      fadeOpenTarget: null
    })
//    console.log('add fade closed')
    this.addFadeClosedEvent()
  }

  render () {
//    console.log('render', this.state)
    const isFadeOpen = !!this.state.fadeOpenTarget
    const isFadeOpened = this.state.isFadeOpened
    const fadeOpenTargetId = isFadeOpen ? this.state.fadeOpenTarget.id : null
    return (
      <ImageZoomContainer>
        <div
          className={isFadeOpen ? 'fade-layer fade-layer-open' : 'fade-layer'}
          onClick={isFadeOpened ? this.screenClickHandler : null}
          //onTouchStart={isFadeOpened ? this.screenClickHandler : null}
        >
          {this.state.elements.map((data, i) => {
            return <img
              key={i}
              id={data.getAttribute('data-node-id')}
              className={fadeOpenTargetId === data.getAttribute('data-node-id') ? 'article-image-modal article-image-modal-open' : 'article-image-modal'}
              src={data.querySelector('img').getAttribute('src')}
              srcSet={data.querySelector('img').getAttribute('srcset')}
            />
          })}
        </div>
      </ImageZoomContainer>
    )
  }
}

const Related = props => {
  const data = props.data
  const context = props.context
  if (!data.related || data.related.totalCount === 0) return null
  // markdownの定義順に並び替え
  const sorted = context.related.map((slug, key) => {
    return data.related.edges.find((edge) => {
      return edge.node.fields.slug === slug
    })
  })

  return (
    <RelatedContainer>
      <StyledTitle>
        <h2>関連する読みもの</h2>
      </StyledTitle>
      <CardContainer>
        {sorted.map((edge, key) => {
          return (
            <RelateCard key={key}
                        title={edge.node.fields.title}
                        text={edge.node.fields.lead}
                        image={edge.node.fields.image.childImageSharp}
                        date={edge.node.fields.date}
                        author={edge.node.fields.author}
                        link={edge.node.fields.slug}
            />
          )
        })}
      </CardContainer>
    </RelatedContainer>
  )
}

const ArticlePage = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title={data.article.fields.title} keywords={[`働き方`, `リモートデスクトップ`, `時短`]} />
      <MainArticle article={data.article}/>
      <Related data={data} context={pageContext} />
      <ImageZoom/>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!, $related: [String]) {
    article: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        image {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        avatar {
          childImageSharp {
            fixed(width: 80, height: 80, cropFocus: CENTER, quality: 90) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        title
        date(formatString: "YYYY年M月D日")
        author
        lead
        showProfiles
        profile
        show_top_image
      }
    }
    related: allMarkdownRemark (
      filter: {
        fields: {
          reserved: { eq: false },
          slug: { in: $related }
        } 
      }) {
      totalCount
      edges {
        node {
          id
          fields {
            title
            date(formatString: "YYYY年M月D日")
            image {
              childImageSharp {
                fixed(width: 112, height: 120, cropFocus: CENTER, quality: 90) {
                  width
                  height
                  src
                  srcSet
                }
              }
            }
            summary
            slug
            author
          }
        }
      }
    }
  }
`

export default ArticlePage;
