import React from "react";
import styled from "styled-components";
import Profile from "./profile";
import Img from "gatsby-image";
import { Link } from "gatsby";


const ArticleSection = styled.section`
  display: grid;
  grid-template-columns: 8px 1fr 8px;
  max-width: 720px;
  margin: 0 auto;
  align-items: center;
  justify-items: center;

  .imgholder {
    grid-column: 2/3;
    grid-row: 1/2;
    overflow: hidden;
    width: 100%;
    margin-bottom: 16px;
  }

  .imgholder img {
    object-fit: contain;
  }

  .imgholder2 {
    grid-column: 1/-1;
    grid-row: 4/5;
    overflow: hidden;
  }

  .first {
    grid-column: 2/3;
    grid-row: 3/4;
    margin-top: 26px;
    font-feature-settings: palt;
    white-space: pre-wrap;
    font-weight: bold;
  }

  .second {
    grid-column: 2/3;
    grid-row: 6/7;
    font-feature-settings: palt;
    
    .gatsby-resp-image-wrapper {
      cursor: pointer;
      border-radius: 16px;
      overflow: hidden;
    }

    figcaption.gatsby-resp-image-figcaption {
      font-size: 80%;
      text-align: center;      
      font-style: italic;
      color: #999;
      width: 100%;
    }
    
    h2 { 
      margin-top: 3rem;
    }
    
    pre {
      background: #FEE;
      border-radius: 4px;
      code {
        word-break: break-all;
      }
    }
    
    .explain {
      background-color: #ffe4e1;
      padding: 32px;
      border-radius: 16px;
      margin-bottom: 1.45em;
      padding-bottom: 16px;
    }
  }

  .profiles {
    grid-column: 2/3;
    grid-row: 7/8;
    margin-top: 40px;
  }

  .cap {
    font-size: 9px;
    grid-column: 2/3;
    grid-row: 5/6;
  }
`;

const TitleBox = styled.div`
  border-bottom: 2px solid black;

  grid-column: 2/3;
  grid-row: 2/3;
  width: 100%;
  
  h2 {
    text-align: left;
    margin: 0;
    line-height: 1.3em;
    font-weight: 300;
  }
  p {
    text-align: right;
    margin: 16px 0;
    a {
      text-decoration: none;
      color: black;
    }
  }
`;

const MainArticle = props => {
  const topImage = props.article.fields.show_top_image ? <div className="imgholder">
    <Img
      style={{ borderRadius: "16px" }}
      fluid={props.article.fields.image.childImageSharp.fluid} alt={props.article.fields.title}/>
  </div> : null

  return (
    <ArticleSection>
      { topImage }
      <TitleBox>
        <h2>{props.article.fields.title}</h2>
        <p>
          {props.article.fields.date}
          <br/>
          <Link to={`/author/`+props.article.fields.author}>
            {props.article.fields.author}
          </Link>
        </p>
      </TitleBox>
      <p className="first" dangerouslySetInnerHTML={{ __html: props.article.fields.lead }} />
      <div className="second" dangerouslySetInnerHTML={{ __html: props.article.html }} />
      {props.article.fields.showProfiles &&
      <div className="profiles">
        <Profile
          image={props.article.fields.avatar}
          alt="profile"
          content={props.article.fields.profile}
        />
      </div>}
   </ArticleSection>
     )
}

export default MainArticle;
