import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

const CardBase = styled.div`
  width: 320px;
  height: 120px;
  /* background-color: lightgrey; */
  position: relative;
  overflow: hidden;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 8px 200px 112px;
  -ms-grid-columns: 8px 200px 112px;
  grid-template-rows: 90px 30px;
  -ms-grid-rows: 90px 30px;
  grid-column-gap: 4px;
  box-shadow: 1px 1px 3px grey;

  .holder {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
    grid-column: 3/4;
    grid-row: 1/-1;
    height: 120px;
    background-color: offwhite;
  }

  .textholder {
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    height: 90px;
    grid-row: 1/2;
    grid-column: 2/3;
  }

  .holder img {
    object-fit: cover;
    height: 110%;
  }

  h3 {
    font-size: 12px;
    padding: 8px 0;
    font-weight: 600;
    line-height: 1.2em;
  }

  a {
    text-decoration: none;
    color: black;
  }

  span {
    display: block;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 10px;
    text-align: end;
    padding: 4px 8px;
  }
`;

const RelateCard = props => (
  <CardBase>
    <div className="holder">
      <Img fixed={props.image.fixed} alt={props.alt} />
    </div>
    <div className="textholder">
      <Link to={props.link}>
        <h3>{props.title}</h3>
      </Link>
    </div>
    <span>{ props.date }　{ props.author }</span>
  </CardBase>
);

export default RelateCard;
